export const POTENTIAL_CONFIRMED_OPTIONS = [
  { id: 1, name: 'Potential', value: false },
  { id: 2, name: 'Confirmed', value: true }
];

export const ATHLETE_PILL_STEP_INITIATE = 'Initiate';
export const ATHLETE_PILL_STEP_BULK_INVITE = 'BulkInvite';
export const ATHLETE_PILL_STEP_MANUAL_INVITE = 'ManualInvite';

export const ROLE_TYPE_NAME_ATHLETE = 'Athlete';
export const ATTENDEE_TYPE_NAME_ATHLETE = 'Athlete';

export const MEMBER_INVITE_STATUS_INVITED = 'Invited';
export const MEMBER_INVITE_STATUS_SUBMITTED = 'Submitted';

const NTGroupConstants = {
  POTENTIAL_CONFIRMED_OPTIONS,
  ATHLETE_PILL_STEP_INITIATE,
  ATHLETE_PILL_STEP_BULK_INVITE,
  ATHLETE_PILL_STEP_MANUAL_INVITE,
  ROLE_TYPE_NAME_ATHLETE,
  ATTENDEE_TYPE_NAME_ATHLETE,
  MEMBER_INVITE_STATUS_INVITED,
  MEMBER_INVITE_STATUS_SUBMITTED
};

export default NTGroupConstants;