import HierarchicalDataUtils from "../../../common/utils/HierarchicalDataUtils";
import { BASIC_INITIAL_STATE, NO_DATA_MESSAGE, PersonHttpHelper } from "../../../common/utils/HttpHelper";

const INITIAL_NATIONAL_TEAM_GROUP_TYPES_HIERARCHY_STATE = {
  ...BASIC_INITIAL_STATE,
  treeData: []
};

const sortByHasChildren = (a, b) => {
  const aHasChildren = Array.isArray(a?.children);
  const bHasChildren = Array.isArray(b?.children);

  if (aHasChildren === true && bHasChildren === false) {
    return -1;
  } else if (aHasChildren === false && bHasChildren === true) {
    return 1;
  } else {
    return 0;
  }
};

const getNTGroupTypesHierarchyData = (state, setState) => {
  if (state.isArrayLoading !== true && state.isArrayLoaded !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const url = `/NationalTeamGroupType/Hierarchy`;
    PersonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          const sortedData = arrayData.sort((a, b) => sortByHasChildren(a, b));

          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            arrayData: sortedData,
            treeData: HierarchicalDataUtils.MapArrayToTreeData(sortedData, 'id', 'name'),
            message: ''
          };
          setState(newState);
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message
        });
      });
  }
};

const NTGroupTypesHierarchyData = {
  INITIAL_NATIONAL_TEAM_GROUP_TYPES_HIERARCHY_STATE,
  getNTGroupTypesHierarchyData
};

export default NTGroupTypesHierarchyData;