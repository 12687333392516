import { useEffect } from 'react';

import useNTGroupData from '../../../../state/nationalTeamGroup/UseNTGroupData';
import useNTGroupAttendeeTypesData from '../../../../state/nationalTeamGroupAttendeeTypes/UseNTGroupAttendeeTypesData';

const useNTGroupAttendeeTypeDropdown = () => {
  const { onNTGroupsError } = useNTGroupData();
  const { nationalTeamGroupAttendeeTypesState, getNTGroupAttendeeTypes } = useNTGroupAttendeeTypesData();

  useEffect(() => {
    if (nationalTeamGroupAttendeeTypesState.isArrayLoaded !== true) {
      const getNTGroupAttendeeTypesPromise = getNTGroupAttendeeTypes();

      if (getNTGroupAttendeeTypesPromise ?? false) {
        getNTGroupAttendeeTypesPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    nationalTeamGroupAttendeeTypesState
  };
};

export default useNTGroupAttendeeTypeDropdown;