import { useState } from "react";
import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTGroupData from "../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupInfoData from "../../../state/nationalTeamGroup/nationalTeamGroupInfo/UseNTGroupInfoData";
import useNTGroupMembersData from "../../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";

const INITIAL_STATE = {
  displayDeletePopup: false
};

const useNTGroupInfo = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { onNTGroupsError } = useNTGroupData();
  const { groupInfoState, groupDeleteState, putNTGroupInfo, deleteNTGroup } = useNTGroupInfoData();
  const { groupMembersState } = useNTGroupMembersData();
  const [state, setState] = useState(INITIAL_STATE);

  const onDeletePopupConfirmClicked = (event) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDeletePopup: false
      });

      const deleteNTGroupPromise = deleteNTGroup(groupInfoState.nationalTeamGroupId);

      if (deleteNTGroupPromise ?? false) {
        deleteNTGroupPromise.then((newState) => {
          if (newState ?? false) {
            navigate(navRoutes.NT_GROUP_SEARCH?.route);
          }
        }).catch((e) => {
          onNTGroupsError(e);
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onDeletePopupCancelClicked = (event) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDeletePopup: false
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onDeleteGroupClicked = (event) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDeletePopup: true
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onSubmitInfoForm = (formState) => {
    try {
      const groupInfoObj = {
        ...groupInfoState.objData,
        nationalTeamGroupName: formState.nationalTeamGroupName,
        nationalTeamGroupCode: formState.nationalTeamGroupCode,
        effectiveDate: formState.effectiveDate,
        expirationDate: formState.expirationDate,
        registrationStartDate: formState.hasRegistration === true ? formState.registrationStartDate : null,
        registrationEndDate: formState.hasRegistration === true ? formState.registrationEndDate : null,
        meetId: formState.hasMeet === true ? formState.meetId : null
      };

      const putNTGroupInfoPromise = putNTGroupInfo(groupInfoState.nationalTeamGroupId, groupInfoObj);

      if (putNTGroupInfoPromise ?? false) {
        putNTGroupInfoPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  return {
    isSaving: groupInfoState.isObjLoading || groupDeleteState.isObjLoading,
    groupInfoObj: groupInfoState.objData || {},
    groupMembersObj: groupMembersState.objData || {},
    state,
    onSubmitInfoForm,
    onDeleteGroupClicked,
    onDeletePopupCancelClicked,
    onDeletePopupConfirmClicked
  };
};

export default useNTGroupInfo;