import { createContext, useState } from 'react';

import NTGroupInfoData from './NTGroupInfoData';

export const NTGroupInfoContext = createContext();

const NTGroupInfoContextProvider = ({ children }) => {
  const stateHook = useState(NTGroupInfoData.INITIAL_GROUP_INFO_STATE);

  return (
    <NTGroupInfoContext.Provider value={stateHook}>
      {children}
    </NTGroupInfoContext.Provider>
  );
};

export default NTGroupInfoContextProvider;