import { useEffect } from 'react';

import validate from './NTGroupAthleteEventAddFormValidation';

import useNTGroupData from '../../../../state/nationalTeamGroup/UseNTGroupData';

import { BLANK_DATE_STRING, DEFAULT_ID } from '../../../../../common/utils/Constants';
import useForm from "../../../../../common/utils/UseForm";

const INITIAL_FORM_STATE = {
  eventId: DEFAULT_ID,
  eventName: '',
  swimTime: '',
  swimTimeValue: '',
  swimDate: BLANK_DATE_STRING,
  meetName: '',
};

const useNTGroupAthleteEventAddForm = (onSubmitFormCallback) => {
  const { onNTGroupsError } = useNTGroupData();
  const { formState, errorState, isDirty, isSubmitting, setFormData, handleSubmit, updateFormState, onValueTextPairChanged
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  useEffect(() => {
    try { // reset form
      if (isDirty === false && isSubmitting === false) {
        setFormData(INITIAL_FORM_STATE, true);
      }
    } catch (e) {
      onNTGroupsError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isSubmitting]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onValueTextPairChanged
  };
};

export default useNTGroupAthleteEventAddForm;