import { useContext, useState } from "react";

import NTGroupMembersData from "./NTGroupMembersData";
import { NTGroupMembersContext } from "./NTGroupMembersContextProvider";

const useNTGroupMembersData = () => {
  const [groupMembersState, setGroupMembersState] = useContext(NTGroupMembersContext);
  const [groupMemberPostState, setGroupMemberPostState] = useState(NTGroupMembersData.INITIAL_GROUP_MEMBER_POST_STATE);
  const [groupMemberDeleteState, setGroupMemberDeleteState] = useState(NTGroupMembersData.INITIAL_GROUP_MEMBER_DELETE_STATE);

  const postNTGroupMember = (groupMemberObj, sendInvite = false) => {
    return NTGroupMembersData.postNTGroupMemberData(groupMemberObj, sendInvite, groupMemberPostState, setGroupMemberPostState);
  };

  const postNTGroupMembersInitiate = (nationalTeamGroupId) => {
    return NTGroupMembersData.postNTGroupMembersInitiateData(nationalTeamGroupId, groupMembersState, setGroupMembersState);
  };

  const getNTGroupMembers = (nationalTeamGroupId) => {
    return NTGroupMembersData.getNTGroupMembersData(nationalTeamGroupId, groupMembersState, setGroupMembersState);
  };

  const putNTGroupMembers = (nationalTeamGroupId, groupMembersArray, sendInvite = false) => {
    return NTGroupMembersData.putNTGroupMembersData(nationalTeamGroupId, groupMembersArray, sendInvite, groupMembersState, setGroupMembersState);
  };

  const deleteNTGroupMember = (personNationalTeamGroupId) => {
    return NTGroupMembersData.deleteNTGroupMemberData(personNationalTeamGroupId, groupMemberDeleteState, setGroupMemberDeleteState);
  };

  return {
    groupMembersState,
    groupMemberPostState,
    groupMemberDeleteState,
    postNTGroupMember,
    postNTGroupMembersInitiate,
    getNTGroupMembers,
    putNTGroupMembers,
    deleteNTGroupMember
  };
};

export default useNTGroupMembersData;