import { Fragment } from "react";

import useNTGroupAdd from "./UseNTGroupAdd";

import NTGroupAddForm from "../components/forms/NTGroupAddForm";
import NTGroupsLoadingModal from "../components/dialogs/NTGroupsLoadingModal";

import Headings from "../../../../common/components/headings/Headings";

import { SAVING_MSG } from "../../../../common/utils/Constants";

// import global from '../../../../common/components/GlobalStyle.module.css';

const NTGroupAdd = () => {
  const {
    isSaving,
    onSubmitInfoForm,
    onBackClicked
  } = useNTGroupAdd();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>National Team Group Add</Headings.H3>
        </div>
      </div>
      <NTGroupAddForm
        onSubmitFormCallback={onSubmitInfoForm}
        onSecondaryButtonClicked={onBackClicked} />
      <NTGroupsLoadingModal
        isLoading={isSaving}
        loadingMessage={SAVING_MSG} />
    </Fragment>
  );
};

export default NTGroupAdd;