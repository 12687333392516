import useNTGroupData from "../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupMembersData from "../../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";

const useNTGroupAthletesBulkInvite = (updateAthletePillStep) => {
  const { onNTGroupsError } = useNTGroupData();
  const { groupMembersState, putNTGroupMembers } = useNTGroupMembersData();

  const onSubmitAthletesFormGrid = (formGridState) => {
    try {
      const groupMembersArray = [...formGridState];

      const putNTGroupMembersPromise = putNTGroupMembers(groupMembersState.nationalTeamGroupId, groupMembersArray, true);

      if (putNTGroupMembersPromise ?? false) {
        putNTGroupMembersPromise.then((newState) => {
          if (newState ?? false) {
            updateAthletePillStep(newState.objData);
          }
        }).catch((e) => {
          onNTGroupsError(e);
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  return {
    isSaving: groupMembersState.isObjLoading,
    gridData: groupMembersState.objData?.nationalTeamGroupMember || [],
    onSubmitAthletesFormGrid
  };
};

export default useNTGroupAthletesBulkInvite;