import { DEFAULT_ID } from "../../../../common/utils/Constants";
import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const getNTGroupInfoData = (nationalTeamGroupId, state, setState) => {
  if (nationalTeamGroupId > 0) {
    const newState = {
      ...state,
      nationalTeamGroupId
    };
    
    const api = initApi(API_NAMES.PERSON, newState, setState);

    const nationalTeamGroupIdForUrl = encodeURIComponent(nationalTeamGroupId);
    const url = `/NationalTeamGroup/${nationalTeamGroupIdForUrl}/info`;

    return api.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const putNTGroupInfoData = (nationalTeamGroupId, groupInfoObj, state, setState) => {
  if (nationalTeamGroupId > 0 && groupInfoObj) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const nationalTeamGroupIdForUrl = encodeURIComponent(nationalTeamGroupId);
    const url = `/NationalTeamGroup/${nationalTeamGroupIdForUrl}/info`;

    return api.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const deleteNTGroupData = (nationalTeamGroupId, state, setState) => {
  if (nationalTeamGroupId > 0) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const nationalTeamGroupIdForUrl = encodeURIComponent(nationalTeamGroupId);
    const url = `/NationalTeamGroup/${nationalTeamGroupIdForUrl}`;

    return api.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const INITIAL_GROUP_INFO_STATE = {
  ...BASIC_INITIAL_STATE,
  nationalTeamGroupId: DEFAULT_ID
}; 

const INITIAL_GROUP_DELETE_STATE = {
  ...BASIC_INITIAL_STATE
}; 

const NTGroupInfoData = {
  INITIAL_GROUP_INFO_STATE,
  INITIAL_GROUP_DELETE_STATE,
  getNTGroupInfoData,
  putNTGroupInfoData,
  deleteNTGroupData
};

export default NTGroupInfoData;