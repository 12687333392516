import { useEffect, useState } from "react";

import {
  ATHLETE_PILL_STEP_BULK_INVITE, ATHLETE_PILL_STEP_INITIATE, ATHLETE_PILL_STEP_MANUAL_INVITE
} from "../utils/NTGroupConstants";

import useNTGroupData from "../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupMembersData from "../../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";

const INITIAL_STATE = {
  currentStep: null
};

const useNTGroupAthletes = () => {
  const { onNTGroupsError } = useNTGroupData();
  const { groupMembersState } = useNTGroupMembersData();
  const [state, setState] = useState(INITIAL_STATE);

  const updateAthletePillStep = (groupMembersObj) => {
    try {
      setState({
        ...state,
        currentStep: getCurrentAthletePillStep(groupMembersObj)
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  useEffect(() => {
    try {
      if (state.currentStep === null) {
        setState({
          ...state,
          currentStep: getCurrentAthletePillStep(groupMembersState.objData)
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCurrentAthletePillStep(groupMembersObj) {
    if (groupMembersObj.invitationSent === true) {
      return ATHLETE_PILL_STEP_MANUAL_INVITE;
    } else if (groupMembersObj.hasMembers === true) {
      return ATHLETE_PILL_STEP_BULK_INVITE;
    } else if (groupMembersObj.hasMembers === false) {
      return ATHLETE_PILL_STEP_INITIATE;
    } else {
      throw new Error('Failed to load Athletes pill step');
    }
  }

  return {
    currentStep: state.currentStep,
    updateAthletePillStep
  };
};

export default useNTGroupAthletes;