import { createContext, useState } from 'react';
import NTGroupAttendeeTypesData from './NTGroupAttendeeTypesData';

export const NTGroupAttendeeTypesStateContext = createContext();

const NTGroupAttendeeTypesContextProvider = ({ children }) => {
  const stateHook = useState(NTGroupAttendeeTypesData.INITIAL_NATIONAL_TEAM_GROUP_ATTENDEE_TYPES_STATE);

  return (
    <NTGroupAttendeeTypesStateContext.Provider value={stateHook}>
      {children}
    </NTGroupAttendeeTypesStateContext.Provider>
  );
};

export default NTGroupAttendeeTypesContextProvider;