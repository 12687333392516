import { NO_DATA_MESSAGE, PersonHttpHelper } from "../../../../../common/utils/HttpHelper";
import { isValidMemberId } from "../../../../../common/utils/validation";

const localValidate = (formState) => {
  let errors = {};

  if (formState.memberId.trim().length === 0) {
    errors.memberId = 'Member Id is required'
  } else if (isValidMemberId(formState.memberId.trim()) === false) {
    errors.memberId = 'Member Id must be 14 alphanumeric characters'
  }

  return errors;
};

const serverValidate = async (formState) => {
  let errors = {};

  const memberIdForUrl = formState.memberId ? encodeURIComponent(formState.memberId) : 'NaN';
  const url = `/Person/flat/memberId/${memberIdForUrl}`;

  await PersonHttpHelper(url, 'GET')
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      }
      else {
        formState.matchedMember = objData;
      }
    })
    .catch(() => {
      errors.apiError = 'Member Id is not valid'
    });

  return errors;
};


const NTGroupMemberIdFormValidation = async (formState) => {
  let errors = localValidate(formState);

  if (!errors || Object.keys(errors).length === 0) {
    errors = await serverValidate(formState);
  }

  return errors ? errors : {};
};

export default NTGroupMemberIdFormValidation;