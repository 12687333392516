import { useState } from "react";

import NTGroupAddData from "./NTGroupAddData";

const useNTGroupAddData = () => {
  const [groupAddState, setGroupAddState] = useState(NTGroupAddData.INITIAL_GROUP_ADD_STATE);

  const postNTGroup = (groupObj) => {
    return NTGroupAddData.postNTGroupData(groupObj, groupAddState, setGroupAddState);
  };

  return {
    groupAddState,
    postNTGroup
  };
};

export default useNTGroupAddData;