import { DEFAULT_ID } from "../../../../common/utils/Constants";
import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const postNTGroupMemberData = (groupMemberObj, sendInvite, state, setState) => {
  if (groupMemberObj) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const url = `/PersonNationalTeamGroup?sendInvite=${sendInvite === true}`;

    return api.executeObject ? api.executeObject(url, 'POST', groupMemberObj) : null;
  }
};

const postNTGroupMembersInitiateData = (nationalTeamGroupId, state, setState) => {
  if (nationalTeamGroupId > 0) {
    const newState = {
      ...state,
      nationalTeamGroupId
    };
    
    const api = initApi(API_NAMES.PERSON, newState, setState);

    const nationalTeamGroupIdForUrl = encodeURIComponent(nationalTeamGroupId);
    const url = `/NationalTeamGroup/${nationalTeamGroupIdForUrl}/PersonNationalTeamGroup/Initiate`;

    return api.executeObject ? api.executeObject(url, 'POST') : null;
  }
};

const getNTGroupMembersData = (nationalTeamGroupId, state, setState) => {
  if (nationalTeamGroupId > 0) {
    const newState = {
      ...state,
      nationalTeamGroupId
    };

    const api = initApi(API_NAMES.PERSON, newState, setState);

    const nationalTeamGroupIdForUrl = encodeURIComponent(nationalTeamGroupId);
    const url = `/NationalTeamGroup/${nationalTeamGroupIdForUrl}/Member`;

    return api.executeObject ? api.executeObject(url, 'GET') : null;
  }
};

const putNTGroupMembersData = (nationalTeamGroupId, groupMembersArray, sendInvite, state, setState) => {
  if (nationalTeamGroupId > 0 && groupMembersArray) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const nationalTeamGroupIdForUrl = encodeURIComponent(nationalTeamGroupId);
    const url = `/NationalTeamGroup/${nationalTeamGroupIdForUrl}/Member?sendInvite=${sendInvite === true}`;

    return api.executeObject ? api.executeObject(url, 'PUT', groupMembersArray) : null;
  }
};

const deleteNTGroupMemberData = (personNationalTeamGroupId, state, setState) => {
  if (personNationalTeamGroupId > 0) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const personNationalTeamGroupIdForUrl = encodeURIComponent(personNationalTeamGroupId);
    const url = `/PersonNationalTeamGroup/${personNationalTeamGroupIdForUrl}`;

    return api.executeObject ? api.executeObject(url, 'DELETE') : null;
  }
};

const INITIAL_GROUP_MEMBERS_STATE = {
  ...BASIC_INITIAL_STATE,
  nationalTeamGroupId: DEFAULT_ID
};

const INITIAL_GROUP_MEMBER_POST_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_GROUP_MEMBER_DELETE_STATE = {
  ...BASIC_INITIAL_STATE
};

const NTGroupMembersData = {
  INITIAL_GROUP_MEMBERS_STATE,
  INITIAL_GROUP_MEMBER_POST_STATE,
  INITIAL_GROUP_MEMBER_DELETE_STATE,
  postNTGroupMemberData,
  postNTGroupMembersInitiateData,
  getNTGroupMembersData,
  putNTGroupMembersData,
  deleteNTGroupMemberData
};

export default NTGroupMembersData;