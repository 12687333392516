import { createContext, useState } from 'react';

import NTGroupTypesHierarchyData from "./NTGroupTypesHierarchyData";

export const NTGroupTypesHierarchyContext = createContext();

const NTGroupTypesHierarchyContextProvider = ({ children }) => {
  const stateHook = useState(NTGroupTypesHierarchyData.INITIAL_NATIONAL_TEAM_GROUP_TYPES_HIERARCHY_STATE);

  return (
    <NTGroupTypesHierarchyContext.Provider value={stateHook}>
      {children}
    </NTGroupTypesHierarchyContext.Provider>
  );
};

export default NTGroupTypesHierarchyContextProvider;