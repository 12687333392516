import { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ event }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{event.eventName || ''}</span>&nbsp;
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {event.eventName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Swim Time
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {event.swimTime ? formatTimeForDisplay(event.swimTime) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Swim Date
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {event.swimDate ? formatDate(event.swimDate) : <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Meet Name
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {event.meetName || <span>&nbsp;</span>}
        </div>
      </div>
    </div>
  </div >
);

const DetailTable = ({ events }) => (
  <div className={global.SmallTableDetailTable}>
    {Array.isArray(events) && events.length > 0 &&
      events.map((event, i) => <DetailTableRow key={i} event={event} />)
    }
  </div>
);

const GridRow = ({ groupAthlete, expandedId, onExpandClicked, onDeleteClicked }) => (
  <Fragment>
    <div className={global.SmallTableRow}>
      <div className={global.SmallTableRowHead}><span className='hidden-xs'>{groupAthlete.preferredName || groupAthlete.firstName} {groupAthlete.lastName}</span>&nbsp;
        <div className={global.FloatRight}>
          <button className={global.IconButtonMobileMargin}
            type="button"
            onClick={(e) => onExpandClicked(e, groupAthlete.personNationalTeamGroupId)}>
            {expandedId === groupAthlete.personNationalTeamGroupId ? <HideIcon /> : <ShowIcon />}
          </button>
          <button
            className={global.IconButtonMobileMargin}
            type="button"
            onClick={(e) => onDeleteClicked(e, groupAthlete)}>
            <DeleteIcon />
          </button>
        </div>
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
            Name
          </div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
            {groupAthlete.preferredName || groupAthlete.firstName || <span>&nbsp;</span>} {groupAthlete.lastName || <span>&nbsp;</span>}
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
            Invite Status
          </div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            {groupAthlete.inviteStatus || <span>&nbsp;</span>}
          </div>
        </div>
      </div>
    </div>
    {expandedId === groupAthlete.personNationalTeamGroupId &&
      <DetailTable events={groupAthlete.personNationalTeamGroupEvent} />
    }
  </Fragment>
);

const NTGroupAthletesGridSmall = ({ gridData, isLoading, expandedId, onExpandClicked, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((groupAthlete, i) => <GridRow key={i} groupAthlete={groupAthlete} expandedId={expandedId} onExpandClicked={onExpandClicked} onDeleteClicked={onDeleteClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Group Athletes</div>
            </div>
          </div>
        )
    }
  </div>
);

export default NTGroupAthletesGridSmall;