import useNTGroupAttendeeTypeDropdown from './UseNTGroupAttendeeTypeDropdown';

import Dropdown from '../../../../../common/components/dropdowns/Dropdown';

import global from '../../../../../common/components/GlobalStyle.module.css';

const NTGroupAttendeeTypeDropdown = ({ label, name, value, error, message, onChange }) => {
  const { nationalTeamGroupAttendeeTypesState } = useNTGroupAttendeeTypeDropdown();

  return nationalTeamGroupAttendeeTypesState.message
    ? <span className={global.LoadingMsg}>{nationalTeamGroupAttendeeTypesState.message}</span>
    : (
      <Dropdown
        name={name}
        value={value}
        error={error}
        label={label}
        message={message}
        onChange={onChange}
        options={nationalTeamGroupAttendeeTypesState.options || []}
        isLoading={nationalTeamGroupAttendeeTypesState.areOptionsLoaded !== true}
      />
    );
};

export default NTGroupAttendeeTypeDropdown;