import useNTGroupData from "../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupMembersData from "../../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";

const useNTGroupAthletesInitiate = (updateAthletePillStep) => {
  const { onNTGroupsError } = useNTGroupData();
  const { groupMembersState, postNTGroupMembersInitiate } = useNTGroupMembersData();

  const onInitiateClicked = (event) => {
    try {
      event?.preventDefault();

      const postNTGroupMembersInitiatePromise = postNTGroupMembersInitiate(groupMembersState.nationalTeamGroupId);

      if (postNTGroupMembersInitiatePromise ?? false) {
        postNTGroupMembersInitiatePromise.then((newState) => {
          if (newState ?? false) {
            if (newState.objData?.nationalTeamGroupMember.length > 0) {
              updateAthletePillStep(newState.objData);
            } else {
              throw new Error('Failed to initiate Athletes');
            }
          }
        }).catch((e) => {
          onNTGroupsError(e);
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  return {
    isLoading: groupMembersState.isObjLoading,
    onInitiateClicked
  };
};

export default useNTGroupAthletesInitiate;