import NTGroupRoleTypeDropdown from '../dropdowns/NTGroupRoleTypeDropdown';
import NTGroupConfirmedDropdown from '../dropdowns/NTGroupConfirmedDropdown';
import NTGroupAttendeeTypeDropdown from '../dropdowns/NTGroupAttendeeTypeDropdown';

import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ groupMember, onUpdateFormGridState, onDeleteClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className='hidden-xs'>{groupMember.groupMemberName || <span>&nbsp;</span>}</span>&nbsp;
      <div className={global.FloatRight}>
        <button
          className={global.IconButton}
          type="button"
          onClick={(e) => onDeleteClicked(e, groupMember)}>
          <DeleteIcon />
        </button>
      </div>
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {groupMember.groupMemberName || <span>&nbsp;</span>}
        </div>
        <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
          <NTGroupAttendeeTypeDropdown
            label={'Attendee Type'}
            name={`groupAttendeeTypeId${groupMember.personNationalTeamGroupId}`}
            value={groupMember.groupAttendeeTypeId}
            onChange={(newValue, newValueLabel, e) => { e?.target?.value && onUpdateFormGridState(groupMember.personNationalTeamGroupId, newValue, 'groupAttendeeTypeId', newValueLabel, 'attendeeType') }} />
        </div>
        <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
          <NTGroupRoleTypeDropdown
            label={'Role'}
            name={`groupRoleTypeId${groupMember.personNationalTeamGroupId}`}
            value={groupMember.groupRoleTypeId}
            onChange={(newValue, newValueLabel, e) => { e?.target?.value && onUpdateFormGridState(groupMember.personNationalTeamGroupId, newValue, 'groupRoleTypeId', newValueLabel, 'roleType') }} />
        </div>
        <div className={['col-xs-12', global.SmallTableRowData].join(' ')}>
          <NTGroupConfirmedDropdown
            label={'Potential/Confirmed'}
            name={`isConfirmedTypeId${groupMember.personNationalTeamGroupId}`}
            value={groupMember.isConfirmedTypeId}
            onChange={(newValue, newValueLabel, e) => { e?.target?.value && onUpdateFormGridState(groupMember.personNationalTeamGroupId, newValue, 'isConfirmedTypeId', newValueLabel, 'isConfirmedType') }} />
        </div>
      </div>
    </div>
  </div>
);

const NTGroupMembersFormGridSmall = ({ formGridState, onUpdateFormGridState, onDeleteClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {Array.isArray(formGridState) && formGridState.length > 0
      ? formGridState.map((groupMember, i) => <GridRow key={i} groupMember={groupMember} onUpdateFormGridState={onUpdateFormGridState} onDeleteClicked={onDeleteClicked} />)
      : (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={[global.SmallTableRowInfo, global.Bold].join(' ')}>
            &nbsp;&nbsp;No Group Members
          </div>
        </div>
      )
    }
  </div>
);

export default NTGroupMembersFormGridSmall;