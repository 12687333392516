import { useEffect } from 'react';

import useNTGroupData from '../../../../state/nationalTeamGroup/UseNTGroupData';
import useNTGroupRoleTypesData from '../../../../state/nationalTeamGroupRoleTypes/UseNTGroupRoleTypesData';

const useNTGroupRoleTypeDropdown = () => {
  const { onNTGroupsError } = useNTGroupData();
  const { nationalTeamGroupRoleTypesState, getNTGroupRoleTypes } = useNTGroupRoleTypesData();

  useEffect(() => {
    if (nationalTeamGroupRoleTypesState.isArrayLoaded !== true) {
      const getNTGroupRoleTypesPromise = getNTGroupRoleTypes();

      if (getNTGroupRoleTypesPromise ?? false) {
        getNTGroupRoleTypesPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    nationalTeamGroupRoleTypesState
  };
};

export default useNTGroupRoleTypeDropdown;