import { createContext, useState } from 'react';
import NTGroupRoleTypesData from './NTGroupRoleTypesData';

export const NTGroupRoleTypesStateContext = createContext();

const NTGroupRoleTypesContextProvider = ({ children }) => {
  const stateHook = useState(NTGroupRoleTypesData.INITIAL_NATIONAL_TEAM_GROUP_ROLE_TYPES_STATE);

  return (
    <NTGroupRoleTypesStateContext.Provider value={stateHook}>
      {children}
    </NTGroupRoleTypesStateContext.Provider>
  );
};

export default NTGroupRoleTypesContextProvider;