import { createContext, useState } from 'react';

import NTGroupData from './NTGroupData';

export const NTGroupContext = createContext();

const NTGroupContextProvider = ({ children }) => {
  const stateHook = useState(NTGroupData.INITIAL_NATIONAL_TEAM_GROUP_STATE);

  return (
    <NTGroupContext.Provider value={stateHook}>
      {children}
    </NTGroupContext.Provider>
  );
};

export default NTGroupContextProvider;