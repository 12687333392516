import { Fragment } from "react";

import useNTGroupAthletesManualInvite from "./UseNTGroupAthletesManualInvite";

import NTGroupAthletesGrid from "../components/grids/NTGroupAthletesGrid";
import NTGroupMemberIdForm from "../components/forms/NTGroupMemberIdForm";
import NTGroupsLoadingModal from "../components/dialogs/NTGroupsLoadingModal";
import NTGroupMemberDeleteModal from "../components/dialogs/NTGroupMemberDeleteModal";
import NTGroupInviteAthleteModal from "../components/dialogs/NTGroupInviteAthleteModal";

import Headings from "../../../../common/components/headings/Headings";

const NTGroupAthletesManualInvite = () => {
  const {
    isGridLoading,
    gridData,
    state,
    onCloseAddPopup,
    onCloseDeletePopup,
    onSubmitMemberIdForm,
    onDeleteGroupMemberClicked
  } = useNTGroupAthletesManualInvite();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Athletes</Headings.H3>
        </div>
      </div>
      {state.displayAddPopup !== true &&
        <NTGroupMemberIdForm primaryButtonText="Invite Athlete" onSubmitFormCallback={onSubmitMemberIdForm} />
      }
      <NTGroupAthletesGrid
        gridData={gridData}
        isLoading={isGridLoading}
        onDeleteClicked={onDeleteGroupMemberClicked} />
      {state.displayAddPopup === true &&
        <NTGroupInviteAthleteModal memberObj={state.addGroupAthleteObj} onCloseModal={onCloseAddPopup} />
      }
      {state.displayDeletePopup === true &&
        <NTGroupMemberDeleteModal groupMemberObj={state.deleteGroupMemberObj} onCloseModal={onCloseDeletePopup} />
      }
      <NTGroupsLoadingModal isLoading={false} />
    </Fragment>
  );
};

export default NTGroupAthletesManualInvite;