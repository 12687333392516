import { useState } from "react";

import useNTGroupData from "../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupMembersData from "../../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";

const INITIAL_STATE = {
  deleteGroupMemberObj: {},
  addGroupMemberObj: {},
  displayDeletePopup: false,
  displayAddPopup: false
};

const useNTGroupMembers = () => {
  const { onNTGroupsError } = useNTGroupData();
  const { groupMembersState, getNTGroupMembers, putNTGroupMembers } = useNTGroupMembersData();
  const [state, setState] = useState(INITIAL_STATE);

  const onCloseDeletePopup = (event, refreshGrid = false) => {
    try {
      event?.preventDefault();

      if (refreshGrid === true) {
        getNTGroupMembersHelper(groupMembersState.nationalTeamGroupId);
      }

      setState({
        ...state,
        displayDeletePopup: false,
        deleteGroupMemberObj: {}
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onCloseAddPopup = (event, refreshGrid = false) => {
    try {
      event?.preventDefault();

      if (refreshGrid === true) {
        getNTGroupMembersHelper(groupMembersState.nationalTeamGroupId);
      }

      setState({
        ...state,
        displayAddPopup: false,
        addGroupMemberObj: {}
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onDeleteGroupMemberClicked = (event, groupMemberObj) => {
    try {
      event?.preventDefault();

      setState({
        ...state,
        displayDeletePopup: true,
        deleteGroupMemberObj: groupMemberObj
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onSubmitMemberIdForm = (formState) => {
    try {
      setState({
        ...state,
        displayAddPopup: true,
        addGroupMemberObj: formState.matchedMember
      });
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onSubmitMembersFormGrid = (formGridState) => {
    try {
      const groupMembersArray = [...formGridState];

      const putNTGroupMembersPromise = putNTGroupMembers(groupMembersState.nationalTeamGroupId, groupMembersArray);

      if (putNTGroupMembersPromise ?? false) {
        putNTGroupMembersPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  function getNTGroupMembersHelper(nationalTeamGroupId) {
    const getNTGroupMembersPromise = getNTGroupMembers(nationalTeamGroupId);

    if (getNTGroupMembersPromise ?? false) {
      getNTGroupMembersPromise.catch((e) => {
        onNTGroupsError(e);
      });
    }
  }

  return {
    isGridLoading: groupMembersState.isObjLoading,
    gridData: groupMembersState.objData?.nationalTeamGroupMember || [],
    state,
    onCloseAddPopup,
    onCloseDeletePopup,
    onSubmitMemberIdForm,
    onSubmitMembersFormGrid,
    onDeleteGroupMemberClicked
  };
};

export default useNTGroupMembers;