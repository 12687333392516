import { createContext, useState } from 'react';

import NTGroupMembersData from './NTGroupMembersData';

export const NTGroupMembersContext = createContext();

const NTGroupMembersContextProvider = ({ children }) => {
  const stateHook = useState(NTGroupMembersData.INITIAL_GROUP_MEMBERS_STATE);

  return (
    <NTGroupMembersContext.Provider value={stateHook}>
      {children}
    </NTGroupMembersContext.Provider>
  );
};

export default NTGroupMembersContextProvider;