import { Fragment } from "react";

import useNTGroupMembers from "./UseNTGroupMembers";

import NTGroupMemberIdForm from "../components/forms/NTGroupMemberIdForm";
import NTGroupsLoadingModal from "../components/dialogs/NTGroupsLoadingModal";
import NTGroupMemberAddModal from "../components/dialogs/NTGroupMemberAddModal";
import NTGroupMembersFormGrid from "../components/grids/NTGroupMembersFormGrid";
import NTGroupMemberDeleteModal from "../components/dialogs/NTGroupMemberDeleteModal";

import Headings from "../../../../common/components/headings/Headings";

const NTGroupMembers = () => {
  const {
    isGridLoading,
    gridData,
    state,
    onCloseAddPopup,
    onCloseDeletePopup,
    onSubmitMemberIdForm,
    onSubmitMembersFormGrid,
    onDeleteGroupMemberClicked
  } = useNTGroupMembers();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Members</Headings.H3>
        </div>
      </div>
      {state.displayAddPopup !== true &&
        <NTGroupMemberIdForm onSubmitFormCallback={onSubmitMemberIdForm} />
      }
      <NTGroupMembersFormGrid
        gridData={gridData}
        isLoading={isGridLoading}
        onSubmitFormCallback={onSubmitMembersFormGrid}
        onDeleteClicked={onDeleteGroupMemberClicked} />
      {state.displayAddPopup === true &&
        <NTGroupMemberAddModal memberObj={state.addGroupMemberObj} onCloseModal={onCloseAddPopup} />
      }
      {state.displayDeletePopup === true &&
        <NTGroupMemberDeleteModal groupMemberObj={state.deleteGroupMemberObj} onCloseModal={onCloseDeletePopup} />
      }
      <NTGroupsLoadingModal isLoading={false} />
    </Fragment>
  );
};

export default NTGroupMembers;