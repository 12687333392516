import { useEffect } from "react";
import { useNavigate } from "../../../../../common/wrappers/ReactRouterDom";

import useNTGroupData from "../../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupSearchData from "../../../../state/nationalTeamGroupSearch/UseNTGroupSearchData";
import useNTGroupRoleTypesData from "../../../../state/nationalTeamGroupRoleTypes/UseNTGroupRoleTypesData";
import useNTGroupAttendeeTypesData from "../../../../state/nationalTeamGroupAttendeeTypes/UseNTGroupAttendeeTypesData";
import useNTGroupTypesHierarchyData from "../../../../state/nationalTeamGroupTypesHierarchy/UseNTGroupTypesHierarchyData";

import useNavRoutes from "../../../../../common/state/security/UseNavRoutes";
import useSecurityData from "../../../../../common/state/security/UseSecurityData";
import useErrorLoggerData from "../../../../../common/state/errorLogger/UseErrorLoggerData";

import { getErrorObjForLog } from "../../../../../common/utils/ErrorUtils";

const useNTGroupsLoadingModal = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { userInfo } = useSecurityData();
  const { nationalTeamGroupState, resetNationalTeamGroupState } = useNTGroupData();
  const { resetNationalTeamGroupFiltersState } = useNTGroupSearchData();
  const { resetNationalTeamGroupRoleTypesState } = useNTGroupRoleTypesData();
  const { resetNationalTeamGroupAttendeeTypesState } = useNTGroupAttendeeTypesData()
  const { resetNationalTeamGroupTypesHierarchyState } = useNTGroupTypesHierarchyData();
  const { postErrorLog } = useErrorLoggerData();

  const onReloadClicked = (event) => {
    event?.preventDefault();

    resetNationalTeamGroupState();

    const homeRoute = navRoutes.NT_GROUP_SEARCH?.route;
    if (homeRoute && homeRoute !== window.location.pathname) {
      resetNationalTeamGroupFiltersState();
      resetNationalTeamGroupRoleTypesState();
      resetNationalTeamGroupAttendeeTypesState();
      resetNationalTeamGroupTypesHierarchyState();

      navigate(homeRoute);
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (nationalTeamGroupState.isError === true && nationalTeamGroupState.error) {
      postErrorLog(getErrorObjForLog(nationalTeamGroupState.error, userInfo.memberId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamGroupState.isError])

  return {
    nationalTeamGroupState,
    onReloadClicked
  };
};

export default useNTGroupsLoadingModal;