import { Fragment } from "react";

import useNTGroupAthletes from "./UseNTGroupAthletes";

import NTGroupAthletesInitiate from "./NTGroupAthletesInitiate";
import NTGroupAthletesBulkInvite from "./NTGroupAthletesBulkInvite";
import NTGroupAthletesManualInvite from "./NTGroupAthletesManualInvite";

import NTGroupsLoadingModal from "../components/dialogs/NTGroupsLoadingModal";

import {
  ATHLETE_PILL_STEP_BULK_INVITE, ATHLETE_PILL_STEP_INITIATE, ATHLETE_PILL_STEP_MANUAL_INVITE
} from "../utils/NTGroupConstants";

import Headings from "../../../../common/components/headings/Headings";

const NTGroupAthletes = () => {
  const {
    currentStep,
    updateAthletePillStep
  } = useNTGroupAthletes();

  switch (currentStep) {
    case ATHLETE_PILL_STEP_INITIATE:
      return <NTGroupAthletesInitiate updateAthletePillStep={updateAthletePillStep} />;

    case ATHLETE_PILL_STEP_BULK_INVITE:
      return <NTGroupAthletesBulkInvite updateAthletePillStep={updateAthletePillStep} />;

    case ATHLETE_PILL_STEP_MANUAL_INVITE:
      return <NTGroupAthletesManualInvite />;

    default:
      return (
        <Fragment>
          <div className="row">
            <div className="col-xs-12">
              <Headings.H3>Athletes</Headings.H3>
            </div>
          </div>
          <NTGroupsLoadingModal isLoading={true} />
        </Fragment>
      );
  }
};

export default NTGroupAthletes;