import { useEffect } from "react";

import { POTENTIAL_CONFIRMED_OPTIONS } from "../../utils/NTGroupConstants";

import useNTGroupData from "../../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupInfoData from "../../../../state/nationalTeamGroup/nationalTeamGroupInfo/UseNTGroupInfoData";
import useNTGroupMembersData from "../../../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";
import useNTGroupRoleTypesData from "../../../../state/nationalTeamGroupRoleTypes/UseNTGroupRoleTypesData";
import useNTGroupAttendeeTypesData from "../../../../state/nationalTeamGroupAttendeeTypes/UseNTGroupAttendeeTypesData";

import UseForm from "../../../../../common/utils/UseForm";
import { DEFAULT_ID } from "../../../../../common/utils/Constants";

const INITIAL_FORM_STATE = {
  personId: '',
  groupMemberName: '',
  groupAttendeeTypeId: DEFAULT_ID,
  attendeeType: '',
  groupRoleTypeId: DEFAULT_ID,
  roleType: '',
  isConfirmedTypeId: DEFAULT_ID,
  isConfirmedType: ''
};

const useNTGroupMemberAddModal = (memberObj, onCloseModal) => {
  const { onNTGroupsError } = useNTGroupData();
  const { groupInfoState } = useNTGroupInfoData();
  const { nationalTeamGroupRoleTypesState, getNTGroupRoleTypes } = useNTGroupRoleTypesData();
  const { nationalTeamGroupAttendeeTypesState, getNTGroupAttendeeTypes } = useNTGroupAttendeeTypesData();
  const { groupMemberPostState, postNTGroupMember } = useNTGroupMembersData();
  const { formState, errorState, setFormData, onValueTextPairChanged, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, () => { });

  useEffect(() => {
    try {
      if (nationalTeamGroupRoleTypesState.areOptionsLoaded === true && nationalTeamGroupAttendeeTypesState.areOptionsLoaded === true) {
        setFormData({
          ...formState,
          personId: memberObj.personId,
          groupMemberName: `${memberObj?.preferredName || memberObj?.firstName} ${memberObj?.lastName}`,
          groupAttendeeTypeId: nationalTeamGroupAttendeeTypesState.options[0].id,
          attendeeType: nationalTeamGroupAttendeeTypesState.options[0].name,
          groupRoleTypeId: nationalTeamGroupRoleTypesState.options[0].id,
          roleType: nationalTeamGroupRoleTypesState.options[0].name,
          isConfirmedTypeId: POTENTIAL_CONFIRMED_OPTIONS[0].id,
          isConfirmedType: POTENTIAL_CONFIRMED_OPTIONS[0].name
        }, true);
      }
    } catch (e) {
      onNTGroupsError(e);
      onCloseModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberObj, nationalTeamGroupRoleTypesState, nationalTeamGroupAttendeeTypesState]);

  useEffect(() => {
    if (nationalTeamGroupRoleTypesState.areOptionsLoaded !== true) {
      const getNTGroupRoleTypesPromise = getNTGroupRoleTypes();

      if (getNTGroupRoleTypesPromise ?? false) {
        getNTGroupRoleTypesPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamGroupRoleTypesState.areOptionsLoaded]);

  useEffect(() => {
    if (nationalTeamGroupAttendeeTypesState.areOptionsLoaded !== true) {
      const getNTGroupAttendeeTypesPromise = getNTGroupAttendeeTypes();

      if (getNTGroupAttendeeTypesPromise ?? false) {
        getNTGroupAttendeeTypesPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamGroupAttendeeTypesState.areOptionsLoaded]);

  function onSubmitFormCallback(formState) {
    const groupMemberObj = {
      nationalTeamGroupId: groupInfoState.nationalTeamGroupId,
      effectiveDate: groupInfoState.objData.effectiveDate,
      expirationDate: groupInfoState.objData.expirationDate,
      personId: formState.personId,
      groupAttendeeTypeId: formState.groupAttendeeTypeId,
      groupRoleTypeId: formState.groupRoleTypeId,
      isConfirmed: POTENTIAL_CONFIRMED_OPTIONS.find((x) => x.id === formState.isConfirmedTypeId).value
    };

    const postNTGroupMemberPromise = postNTGroupMember(groupMemberObj);

    if (postNTGroupMemberPromise ?? false) {
      postNTGroupMemberPromise.then((newState) => {
        if (newState ?? false) {
          onCloseModal(undefined, true);
        }
      }).catch((e) => {
        onNTGroupsError(e);
        onCloseModal();
      });
    }
  }

  return {
    isSaving: groupMemberPostState.isObjLoading,
    formState,
    errorState,
    onValueTextPairChanged,
    handleSubmit
  };
};

export default useNTGroupMemberAddModal;