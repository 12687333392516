import { useContext } from "react";

import NTGroupTypesHierarchyData from "./NTGroupTypesHierarchyData";
import { NTGroupTypesHierarchyContext } from "./NTGroupTypesHierarchyContextProvider";

const useNTGroupTypesHierarchyData = () => {
  const [nationalTeamGroupTypesHierarchyState, setNationalTeamGroupTypesHierarchyState] = useContext(NTGroupTypesHierarchyContext);
  
  const getNTGroupTypesHierarchy = () => {
    NTGroupTypesHierarchyData.getNTGroupTypesHierarchyData(nationalTeamGroupTypesHierarchyState, setNationalTeamGroupTypesHierarchyState);
  };

  const resetNationalTeamGroupTypesHierarchyState = () => {
    setNationalTeamGroupTypesHierarchyState({
      ...NTGroupTypesHierarchyData.INITIAL_NATIONAL_TEAM_GROUP_TYPES_HIERARCHY_STATE
    });
  };

  return {
    nationalTeamGroupTypesHierarchyState,
    getNTGroupTypesHierarchy,
    resetNationalTeamGroupTypesHierarchyState
  };
};

export default useNTGroupTypesHierarchyData;