import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const postNTGroupData = (groupObj, state, setState) => {
  if (groupObj) {
    const api = initApi(API_NAMES.PERSON, state, setState);
    const url = `/NationalTeamGroup`;

    return api.executeObject ? api.executeObject(url, 'POST', groupObj) : null;
  }
};

const INITIAL_GROUP_ADD_STATE = {
  ...BASIC_INITIAL_STATE
};

const NTGroupAddData = {
  INITIAL_GROUP_ADD_STATE,
  postNTGroupData
};

export default NTGroupAddData;