import useNTGroupSearchForm from "./UseNTGroupSearchForm";

import NTGroupTypesTreeView from "../tree/NTGroupTypesTreeView";

import Input from "../../../../../common/components/inputs/Input";
import YesNoSwitch from "../../../../../common/components/yesNoSwitch/YesNoSwitch";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

import global from '../../../../../common/components/GlobalStyle.module.css';

const NTGroupSearchForm = ({ previousSearchFilters, onSubmitFormCallback, onAddGroupClicked }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onClearFormClicked
  } = useNTGroupSearchForm(previousSearchFilters, onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <NTGroupTypesTreeView
            label='Group Type'
            name='nationalTeamGroupType'
            value={formState.nationalTeamGroupType}
            error={errorState.nationalTeamGroupType}
            message={errorState.nationalTeamGroupType}
            selectableLeavesOnly={true}
            singleSelect={true}
            onChange={(value) => { updateFormState('nationalTeamGroupType', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Group Name'
            name="nationalTeamGroupName"
            value={formState.nationalTeamGroupName}
            error={errorState.nationalTeamGroupName}
            message={errorState.nationalTeamGroupName}
            onChange={(value) => { updateFormState('nationalTeamGroupName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Group Code'
            name="nationalTeamGroupCode"
            value={formState.nationalTeamGroupCode}
            error={errorState.nationalTeamGroupCode}
            message={errorState.nationalTeamGroupCode}
            onChange={(value) => { updateFormState('nationalTeamGroupCode', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <YesNoSwitch
            label='Only Current National Team Groups?'
            name='onlyCurrentNationalTeamGroups'
            checked={formState.onlyCurrentNationalTeamGroups}
            error={errorState.onlyCurrentNationalTeamGroups}
            message={errorState.onlyCurrentNationalTeamGroups}
            onChange={(value) => { updateFormState('onlyCurrentNationalTeamGroups', value) }} />
        </div>
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      {errorState.filterCount &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.filterCount || ''}</p>
          </div>
        </div>
      }
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">Search For Group</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onClearFormClicked}>Clear Form</SecondaryButton>&nbsp;
          <PrimaryButton type="button" onClick={onAddGroupClicked}>Add Group</PrimaryButton>
        </div>
      </div>
    </form>
  );
};

export default NTGroupSearchForm;