import { Fragment } from "react";

import useNTGroupMemberDeleteModal from "./UseNTGroupMemberDeleteModal";

import PopUpModal from "../../../../../common/components/dialogs/PopUpModal";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

import { SAVING_MSG } from "../../../../../common/utils/Constants";

const NTGroupMemberDeleteModal = ({ groupMemberObj, onCloseModal }) => {
  const {
    isSaving,
    onConfirmDeleteClicked
  } = useNTGroupMemberDeleteModal(groupMemberObj, onCloseModal);

  return (
    <Fragment>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Delete Group Member?'}
        displayPopUp={isSaving !== true}
        onModalCanceled={onCloseModal}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Member:</b> {groupMemberObj.groupMemberName}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onConfirmDeleteClicked}>Delete Group Member</PrimaryButton>&nbsp;
            <SecondaryButton type='button' onClick={onCloseModal}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={SAVING_MSG}
        displayPopUp={isSaving} />
    </Fragment>
  );
};

export default NTGroupMemberDeleteModal;