import { Fragment } from "react";

import useNTGroupsContextView from "./UseNTGroupsContextView";

import NTGroupsLoadingModal from "./components/dialogs/NTGroupsLoadingModal";

import Headings from "../../../common/components/headings/Headings";
import LeftArrowIcon from "../../../common/components/icons/LeftArrowIcon";
import LargeContextBasedNav from "../../../common/components/contextBasedNav/LargeContextBasedNav";
import MobileContextBasedNav from "../../../common/components/contextBasedNav/MobileContextBasedNav";

import global from '../../../common/components/GlobalStyle.module.css';

const NTGroupsContextView = ({ children }) => {
  const {
    isLoading,
    groupInfoObj,
    homeRoute,
    taxonomyName,
    onHomeClicked,
    onBackToClicked
  } = useNTGroupsContextView();

  if (isLoading) {
    return <NTGroupsLoadingModal isLoading={true} />;
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onHomeClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              National Team Groups
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.BackToSearchButton}
            type="button"
            onClick={onBackToClicked}>
            <LeftArrowIcon /> Back To National Team Search
          </button>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <p className={global.HeaderText}><strong>Group Name:</strong>&nbsp;{groupInfoObj.nationalTeamGroupName || ''}</p>
          <p className={global.HeaderText}><strong>Group Code:</strong>&nbsp;{groupInfoObj.nationalTeamGroupCode || ''}</p>
          <p className={global.HeaderText}><strong>Group Type:</strong>&nbsp;{groupInfoObj.groupTypeName || ''}</p>
        </div>
      </div>
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName={taxonomyName} />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName={taxonomyName} homeRoute={homeRoute} />
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default NTGroupsContextView;