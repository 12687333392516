const findGroupTypeObjById = (groupTypeArrayData, id) => {
  for (const groupType of groupTypeArrayData) {
    if (groupType.id === id) {
      return groupType;
    } else if (Array.isArray(groupType.children) && groupType.children.length > 0) {
      const childGroupTypeObj = findGroupTypeObjById(groupType.children, id);

      if (childGroupTypeObj) {
        return childGroupTypeObj;
      }
    }
  }

  return null;
};

export {
  findGroupTypeObjById
};