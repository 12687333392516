import { useContext } from "react";

import NTGroupData from "./NTGroupData";
import { NTGroupContext } from "./NTGroupContextProvider";

const useNTGroupData = () => {
  const [nationalTeamGroupState, setNationalTeamGroupState] = useContext(NTGroupContext);

  const onNTGroupsError = (e, errorMessage = undefined, errorTitle = 'National Team Groups Error') => {
    setNationalTeamGroupState({
      ...nationalTeamGroupState,
      isError: true,
      error: e,
      errorTitle,
      errorMessage: errorMessage || e?.message || 'An error occurred.'
    });
  };

  const resetNationalTeamGroupState = () => {
    setNationalTeamGroupState({
      ...NTGroupData.INITIAL_NATIONAL_TEAM_GROUP_STATE
    });
  };

  return {
    nationalTeamGroupState,
    onNTGroupsError,
    resetNationalTeamGroupState
  };
};

export default useNTGroupData;