import EditIcon from "../../../../../common/components/icons/EditIcon";

import { formatDate } from "../../../../../common/utils/DateFunctions";

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ group, onEditGroupClicked }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableRowHead}><span className="hidden-xs">{group.nationalTeamGroupName || ''}</span>&nbsp;
      {onEditGroupClicked &&
        <button
          className={global.IconButton}
          type="button"
          onClick={(e) => onEditGroupClicked(e, group)}>
          <EditIcon />
        </button>
      }
    </div>
    <div className={global.SmallTableRowInfo}>
      <div className="row">
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>Group Name</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3 visible-xs'].join(' ')}>{group.nationalTeamGroupName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Group Code</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{group.nationalTeamGroupCode || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Group Type</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{group.groupTypeName || <span>&nbsp;</span>}</div>
        <div className={[global.SmallTableRowLabels, 'col-xs-6 col-sm-3'].join(' ')}>Effective Date - Expiration Date</div>
        <div className={[global.SmallTableRowData, 'col-xs-6 col-sm-3'].join(' ')}>{group.effectiveDate ? formatDate(group.effectiveDate) : ''} - {group.expirationDate ? formatDate(group.expirationDate) : ''}</div>
      </div>
    </div>
  </div>
);

const NTGroupSearchGridSmall = ({ gridData, isLoading, onEditGroupClicked }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>Loading...</div>
          </div>
        </div>
      ) : Array.isArray(gridData) && gridData.length > 0
        ? gridData.map((group, i) => <GridRow key={i} group={group} onEditGroupClicked={onEditGroupClicked} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Groups</div>
            </div>
          </div>
        )
    }
  </div>
);

export default NTGroupSearchGridSmall;