import { useContext, useState } from "react";

import NTGroupSearchData from "./NTGroupSearchData";
import { NTGroupFiltersContext } from "./NTGroupFiltersContextProvider";

const useNTGroupSearchData = () => {
  const [nationalTeamGroupFiltersState, setNationalTeamGroupFiltersState] = useContext(NTGroupFiltersContext);
  const [nationalTeamGroupSearchState, setNationalTeamGroupSearchState] = useState(NTGroupSearchData.INITIAL_NATIONAL_TEAM_GROUP_SEARCH_STATE);

  const searchNTGroups = (filterObject) => {
    setNationalTeamGroupFiltersState({ ...nationalTeamGroupFiltersState, filterObject });
    return NTGroupSearchData.searchNTGroupsData(JSON.stringify(filterObject), nationalTeamGroupSearchState, setNationalTeamGroupSearchState);
  };

  const onToggleShowFilters = () => {
    setNationalTeamGroupFiltersState({
      ...nationalTeamGroupFiltersState,
      showFilters: !nationalTeamGroupFiltersState.showFilters
    });
  };

  const resetNationalTeamGroupFiltersState = () => {
    setNationalTeamGroupFiltersState({
      ...NTGroupSearchData.INITIAL_NATIONAL_TEAM_GROUP_FILTERS_STATE
    });
  };

  return {
    nationalTeamGroupSearchState,
    nationalTeamGroupFiltersState,
    searchNTGroups,
    onToggleShowFilters,
    resetNationalTeamGroupFiltersState
  };
};

export default useNTGroupSearchData;