import { isValidAlphanumericAndDollarSign } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};
  let filterCount = 0;

  // Group Type
  if (formState.nationalTeamGroupType?.length > 0) {
    filterCount += 1;
  }

  // Group Name
  if (formState.nationalTeamGroupName?.trim()) {
    filterCount += 1;
  }

  // Group Code
  if (formState.nationalTeamGroupCode?.trim()) {
    filterCount += 1;

    if (!isValidAlphanumericAndDollarSign(formState.nationalTeamGroupCode)) {
      errors.nationalTeamGroupCode = 'Group Code can only contain numbers, letters, and dollar signs';
    }
  }

  if (filterCount < 1) {
    errors.filterCount = 'Please enter information in at least one search filter';
  }

  return errors;
};

const NTGroupSearchFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default NTGroupSearchFormValidation;