import { Fragment } from "react";

import useNTGroupReports from "./UseNTGroupReports";

import NTGroupsLoadingModal from "../components/dialogs/NTGroupsLoadingModal";

import ReportList from "../../../../common/components/embeddedReports/ReportList";

const NTGroupReports = () => {
  const {
    routeName,
    reportParameters
  } = useNTGroupReports();

  return (
    <Fragment>
      <ReportList
        listTitle="Reports"
        routeName={routeName}
        reportParameters={reportParameters} />
      <NTGroupsLoadingModal
        isLoading={false} />
    </Fragment>
  );
};

export default NTGroupReports;