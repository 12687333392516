import { useContext } from 'react';
import { NTGroupRoleTypesStateContext } from './NTGroupRoleTypesContextProvider';

import NTGroupRoleTypesData from './NTGroupRoleTypesData';

const useNTGroupRoleTypesData = () => {
  const [nationalTeamGroupRoleTypesState, setNationalTeamGroupRoleTypesState] = useContext(NTGroupRoleTypesStateContext);

  const getNTGroupRoleTypes = () => {
    return NTGroupRoleTypesData.getNTGroupRoleTypesData(nationalTeamGroupRoleTypesState, setNationalTeamGroupRoleTypesState)
      .then((newState) => {
        if (newState ?? false) {
          newState = {
            ...newState,
            options: newState.arrayData.map((x) => { return { id: x.groupRoleTypeId, name: x.roleType } }),
            areOptionsLoaded: true
          };

          setNationalTeamGroupRoleTypesState({ ...newState });

          return newState;
        }

        return null;
      });
  };

  const resetNationalTeamGroupRoleTypesState = () => {
    setNationalTeamGroupRoleTypesState({
      ...NTGroupRoleTypesData.INITIAL_NATIONAL_TEAM_GROUP_ROLE_TYPES_STATE
    });
  };

  return {
    nationalTeamGroupRoleTypesState,
    getNTGroupRoleTypes,
    resetNationalTeamGroupRoleTypesState
  };
};

export default useNTGroupRoleTypesData;