import { useEffect, useState } from "react";

import useNTGroupData from "../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupInfoData from "../../../state/nationalTeamGroup/nationalTeamGroupInfo/UseNTGroupInfoData";

const INITIAL_STATE = {
  reportParameters: { nationalTeamGroupId: '' },
  routeName: ''
};

const useNTGroupReports = () => {
  const { onNTGroupsError } = useNTGroupData();
  const { groupInfoState } = useNTGroupInfoData();
  const [state, setState] = useState({ ...INITIAL_STATE });

  useEffect(() => {
    try {
      if (groupInfoState.nationalTeamGroupId > 0) {
        setState({
          ...state,
          reportParameters: { nationalTeamGroupId: groupInfoState.nationalTeamGroupId },
          routeName: 'NT_GROUP_REPORTS'
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInfoState.nationalTeamGroupId]);

  return {
    ...state
  };
};

export default useNTGroupReports;