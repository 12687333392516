import { Fragment } from 'react';

import NTGroupAthleteEventsGridLarge from './NTGroupAthleteEventsGridLarge';
import NTGroupAthleteEventsGridSmall from './NTGroupAthleteEventsGridSmall';

const NTGroupAthleteEventsGrid = ({ gridData, isLoading, onDeleteClicked }) => (
  <Fragment>
    <NTGroupAthleteEventsGridLarge gridData={gridData} isLoading={isLoading} onDeleteClicked={onDeleteClicked} />
    <NTGroupAthleteEventsGridSmall gridData={gridData} isLoading={isLoading} onDeleteClicked={onDeleteClicked} />
  </Fragment>
);

export default NTGroupAthleteEventsGrid;