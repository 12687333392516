import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const searchNTGroupsData = (filterObject, state, setState) => {
  if (filterObject) {
    const api = initApi(API_NAMES.PERSON, state, setState);

    const filterObjectForUrl = filterObject ? encodeURIComponent(filterObject) : 'NaN';
    const url = `/NationalTeamGroup/search?filters=${filterObjectForUrl}`;

    return api.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const INITIAL_NATIONAL_TEAM_GROUP_SEARCH_STATE = {
  ...BASIC_INITIAL_STATE
};

const INITIAL_NATIONAL_TEAM_GROUP_FILTERS_STATE = {
  filterObject: {},
  showFilters: true
};  

const NTGroupSearchData = {
  INITIAL_NATIONAL_TEAM_GROUP_SEARCH_STATE,
  INITIAL_NATIONAL_TEAM_GROUP_FILTERS_STATE,
  searchNTGroupsData
};

export default NTGroupSearchData;