import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';

import { formatDate } from '../../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import global from '../../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ groupAthleteEvent, onDeleteClicked }) => (
  <tr>
    <td>{groupAthleteEvent.eventName || ''}</td>
    <td>{groupAthleteEvent.swimTime ? formatTimeForDisplay(groupAthleteEvent.swimTime) : ''}</td>
    <td>{groupAthleteEvent.swimDate ? formatDate(groupAthleteEvent.swimDate) : ''}</td>
    <td>{groupAthleteEvent.meetName || ''}</td>
    <td>
      <button
        className={global.IconButtonMargin}
        type="button"
        onClick={(e) => onDeleteClicked(e, groupAthleteEvent)}>
        <DeleteIcon />
      </button>
    </td>
  </tr>
);

const NTGroupAthleteEventsGridLarge = ({ gridData, isLoading, onDeleteClicked }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event</th>
        <th>Swim Time</th>
        <th>Swim Date</th>
        <th>Meet Name</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? <tr><td colSpan="5">Loading...</td></tr>
        : Array.isArray(gridData) && gridData.length > 0
          ? gridData.map((groupAthleteEvent, i) => <GridRow key={i} groupAthleteEvent={groupAthleteEvent} onDeleteClicked={onDeleteClicked} />)
          : <tr><td colSpan="5">No Events</td></tr>
      }
    </tbody>
  </table>
);

export default NTGroupAthleteEventsGridLarge;