import { BLANK_DATE_STRING } from '../../../../../common/utils/Constants';
import { isValidAlphanumericAndDollarSign, isValidDate } from '../../../../../common/utils/validation';

export const localValidate = (formState) => {
  let errors = {};
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);

  // Group Name
  if (!(formState.nationalTeamGroupName?.trim())) {
    errors.nationalTeamGroupName = 'Group Name is required';
  }

  // Group Code
  if (!(formState.nationalTeamGroupCode?.trim())) {
    errors.nationalTeamGroupCode = 'Group Code is required';
  } else if (!isValidAlphanumericAndDollarSign(formState.nationalTeamGroupCode)) {
    errors.nationalTeamGroupCode = 'Group Code can only contain numbers, letters, and dollar signs';
  }

  if (formState.areDatesReadonly !== true) {
    // Effective Date
    if (formState.effectiveDate === BLANK_DATE_STRING) {
      errors.effectiveDate = 'Effective Date is required';
    } else if (!isValidDate(formState.effectiveDate)) {
      errors.effectiveDate = 'Effective Date must be a valid date';
    } else if (formState.effectiveDate === formState.expirationDate) {
      errors.effectiveDate = 'Effective Date and Expiration Date cannot be the same date';
    } else if (expirationDate < effectiveDate) {
      errors.effectiveDate = 'Effective Date must be before the Expiration Date';
    }

    // Expiration Date
    if (formState.expirationDate === BLANK_DATE_STRING) {
      errors.expirationDate = 'Expiration Date is required';
    } else if (!isValidDate(formState.expirationDate)) {
      errors.expirationDate = 'Expiration Date must be a valid date';
    } else if (expirationDate < effectiveDate) {
      errors.expirationDate = 'Expiration Date must be after the Effective Date';
    }
  }

  if (formState.hasRegistration === true) {
    const registrationStartDate = new Date(formState.registrationStartDate);
    const registrationEndDate = new Date(formState.registrationEndDate);

    // Registration Start Date
    if (formState.registrationStartDate === BLANK_DATE_STRING) {
      errors.registrationStartDate = 'Registration Start Date is required';
    } else if (!isValidDate(formState.registrationStartDate)) {
      errors.registrationStartDate = 'Registration Start Date must be a valid date';
    } else if (formState.registrationStartDate === formState.registrationEndDate) {
      errors.registrationStartDate = 'Registration Start Date and Registration End Date cannot be the same date';
    } else if (registrationEndDate < registrationStartDate) {
      errors.registrationStartDate = 'Registration Start Date must be before the Registration End Date';
    }

    // Registration End Date
    if (formState.registrationEndDate === BLANK_DATE_STRING) {
      errors.registrationEndDate = 'Registration End Date is required';
    } else if (!isValidDate(formState.registrationEndDate)) {
      errors.registrationEndDate = 'Registration End Date must be a valid date';
    } else if (registrationEndDate < registrationStartDate) {
      errors.registrationEndDate = 'Registration End Date must be after the Registration Start Date';
    }
  }

  if (formState.hasMeet === true) {
    // Meet
    if (!(formState.meetId > 0)) {
      errors.meetId = 'Meet is required';
    }
  }

  return errors;
};

const NTGroupInfoFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default NTGroupInfoFormValidation;