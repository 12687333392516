import { useEffect } from 'react';

import validate from './NTGroupSearchFormValidation';

import useNTGroupData from '../../../../state/nationalTeamGroup/UseNTGroupData';
import useNTGroupTypesHierarchyData from '../../../../state/nationalTeamGroupTypesHierarchy/UseNTGroupTypesHierarchyData';

import useForm from "../../../../../common/utils/UseForm";
import HierarchicalDataUtils from '../../../../../common/utils/HierarchicalDataUtils';

const INITIAL_FORM_STATE = {
  nationalTeamGroupType: [],
  nationalTeamGroupName: '',
  nationalTeamGroupCode: '',
  onlyCurrentNationalTeamGroups: false
};

const useNTGroupSearchForm = (previousSearchFilters, onSubmitFormCallback) => {
  const { onNTGroupsError } = useNTGroupData();
  const { nationalTeamGroupTypesHierarchyState } = useNTGroupTypesHierarchyData();
  const { formState, errorState, isDirty, handleSubmit, updateFormState, setFormState, setErrors, setIsDirty
  } = useForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);

  const onClearFormClicked = (event) => {
    try {
      event?.preventDefault();

      setFormState({ ...INITIAL_FORM_STATE });
      setErrors({});
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  useEffect(() => {
    try {
      if (nationalTeamGroupTypesHierarchyState.isArrayLoaded === true && Object.keys(previousSearchFilters).length > 0) {
        const oldSearchValues = JSON.parse(JSON.stringify(previousSearchFilters));
        const newFormState = {
          nationalTeamGroupType: HierarchicalDataUtils.GetNameIdPairs(nationalTeamGroupTypesHierarchyState.treeData, [oldSearchValues.nationalTeamGroupTypeId]) || [],
          nationalTeamGroupName: oldSearchValues.nationalTeamGroupName || '',
          nationalTeamGroupCode: oldSearchValues.nationalTeamGroupCode || '',
          onlyCurrentNationalTeamGroups: oldSearchValues.onlyCurrentNationalTeamGroups === true ? true : false
        };

        const validatePromise = validate(newFormState);

        validatePromise.then((errors) => {
          if (Object.keys(errors).length === 0) {
            setFormState({ ...newFormState });
            setIsDirty(true);
            onSubmitFormCallback(newFormState);
          }
        }).catch((e) => {
          onNTGroupsError(e);
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamGroupTypesHierarchyState.isArrayLoaded]);

  useEffect(() => {
    try {
      if (isDirty !== true) {
        setIsDirty(true);
      }
    } catch (e) {
      onNTGroupsError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  return {
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onClearFormClicked
  };
};

export default useNTGroupSearchForm;