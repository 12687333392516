import { useEffect, useState } from "react";

import { ATTENDEE_TYPE_NAME_ATHLETE, MEMBER_INVITE_STATUS_INVITED, ROLE_TYPE_NAME_ATHLETE } from "../../utils/NTGroupConstants";

import useNTGroupData from "../../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupRoleTypesData from "../../../../state/nationalTeamGroupRoleTypes/UseNTGroupRoleTypesData";
import useNTGroupAttendeeTypesData from "../../../../state/nationalTeamGroupAttendeeTypes/UseNTGroupAttendeeTypesData";

const useNTGroupInviteAthletesFormGrid = (gridData, onSubmitFormCallback) => {
  const { onNTGroupsError } = useNTGroupData();
  const { nationalTeamGroupRoleTypesState, getNTGroupRoleTypes } = useNTGroupRoleTypesData();
  const { nationalTeamGroupAttendeeTypesState, getNTGroupAttendeeTypes } = useNTGroupAttendeeTypesData();
  const [formGridState, setFormGridState] = useState([]);
  const [canInviteState, setCanInviteState] = useState(false);

  const onInviteSelectedClicked = (event) => {
    try {
      event?.preventDefault();

      const groupAthletes = [];

      for (const groupAthleteFormObj of formGridState) {
        if (groupAthleteFormObj.isSelected === true) {
          const athleteAttendeeType = nationalTeamGroupAttendeeTypesState.options.find((x) => x.name === ATTENDEE_TYPE_NAME_ATHLETE);
          const athleteRoleType = nationalTeamGroupRoleTypesState.options.find((x) => x.name === ROLE_TYPE_NAME_ATHLETE);

          groupAthletes.push({
            personNationalTeamGroupId: groupAthleteFormObj.personNationalTeamGroupId,
            groupAttendeeTypeId: athleteAttendeeType.id,
            groupRoleTypeId: athleteRoleType.id,
            removeFromGroup: false,
            isConfirmed: true,
            inviteStatus: MEMBER_INVITE_STATUS_INVITED
          });
        } else {
          groupAthletes.push({
            personNationalTeamGroupId: groupAthleteFormObj.personNationalTeamGroupId,
            groupAttendeeTypeId: null,
            groupRoleTypeId: null,
            removeFromGroup: true,
            isConfirmed: false,
            inviteStatus: null
          });
        }
      }

      onSubmitFormCallback(groupAthletes);
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onUpdateAllGridItems = (isSelected) => {
    try {
      const newFormGridState = JSON.parse(JSON.stringify(formGridState));

      for (let i = 0; i < newFormGridState.length; i++) {
        newFormGridState[i].isSelected = isSelected;
      }

      setFormGridState(newFormGridState);
      setCanInviteState(getCanInvite(newFormGridState));
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onUpdateIsSelected = (personNationalTeamGroupId) => {
    try {
      const newFormGridState = JSON.parse(JSON.stringify(formGridState));

      for (let i = 0; i < newFormGridState.length; i++) {
        if (newFormGridState[i].personNationalTeamGroupId === personNationalTeamGroupId) {
          newFormGridState[i].isSelected = !newFormGridState[i].isSelected;
          break;
        }
      }

      setFormGridState(newFormGridState);
      setCanInviteState(getCanInvite(newFormGridState));
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  useEffect(() => {
    try {
      const newFormGridState = [];

      for (const groupAthleteObj of gridData) {
        const newGroupAthleteFormObj = createGroupAthleteFormObj(groupAthleteObj);

        newFormGridState.push(newGroupAthleteFormObj);
      }

      setFormGridState(newFormGridState);
    } catch (e) {
      onNTGroupsError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridData]);

  useEffect(() => {
    if (nationalTeamGroupRoleTypesState.areOptionsLoaded !== true) {
      const getNTGroupRoleTypesPromise = getNTGroupRoleTypes();

      if (getNTGroupRoleTypesPromise ?? false) {
        getNTGroupRoleTypesPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamGroupRoleTypesState.areOptionsLoaded]);

  useEffect(() => {
    if (nationalTeamGroupAttendeeTypesState.areOptionsLoaded !== true) {
      const getNTGroupAttendeeTypesPromise = getNTGroupAttendeeTypes();

      if (getNTGroupAttendeeTypesPromise ?? false) {
        getNTGroupAttendeeTypesPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nationalTeamGroupAttendeeTypesState.areOptionsLoaded]);

  function getCanInvite(newFormGridState) {
    for (const groupAthlete of newFormGridState) {
      if (groupAthlete.isSelected === true) {
        return true;
      }
    }

    return false;
  }

  function createGroupAthleteFormObj(groupAthleteObj) {
    return {
      personNationalTeamGroupId: groupAthleteObj.personNationalTeamGroupId,
      groupAthleteName: `${groupAthleteObj?.preferredName || groupAthleteObj?.firstName} ${groupAthleteObj?.lastName}`,
      events: Array.isArray(groupAthleteObj.personNationalTeamGroupEvent) ? groupAthleteObj.personNationalTeamGroupEvent : [],
      isSelected: false
    };
  }

  return {
    canInviteState,
    formGridState,
    onUpdateIsSelected,
    onUpdateAllGridItems,
    onInviteSelectedClicked
  };
};

export default useNTGroupInviteAthletesFormGrid;