import { Fragment } from 'react';

import useNTGroupMembersFormGrid from './UseNTGroupMembersFormGrid';

import NTGroupMembersFormGridLarge from './NTGroupMembersFormGridLarge';
import NTGroupMembersFormGridSmall from './NTGroupMembersFormGridSmall';

import Spinner from '../../../../../common/components/spinners/Spinner';
import PrimaryButton from '../../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../../common/components/buttons/SecondaryButton';

const NTGroupMembersFormGrid = ({ gridData, isLoading, onSubmitFormCallback, onDeleteClicked }) => {
  const {
    areOptionsLoaded,
    canSaveState,
    formGridState,
    onUpdateFormGridState,
    onSaveClicked,
    onCancelClicked
  } = useNTGroupMembersFormGrid(gridData, onSubmitFormCallback);

  if (areOptionsLoaded !== true || isLoading === true) {
    return <Spinner />;
  }

  return (
    <Fragment>
      <NTGroupMembersFormGridLarge formGridState={formGridState} onUpdateFormGridState={onUpdateFormGridState} onDeleteClicked={onDeleteClicked} />
      <NTGroupMembersFormGridSmall formGridState={formGridState} onUpdateFormGridState={onUpdateFormGridState} onDeleteClicked={onDeleteClicked} />
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          {canSaveState === true &&
            <Fragment>
              <PrimaryButton type="button" onClick={onSaveClicked}>Save</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onCancelClicked}>Cancel</SecondaryButton>
            </Fragment>
          }
        </div>
      </div>
    </Fragment>
  );
};

export default NTGroupMembersFormGrid;