import { useContext } from 'react';
import { NTGroupAttendeeTypesStateContext } from './NTGroupAttendeeTypesContextProvider';

import NTGroupAttendeeTypesData from './NTGroupAttendeeTypesData';

const useNTGroupAttendeeTypesData = () => {
  const [nationalTeamGroupAttendeeTypesState, setNationalTeamGroupAttendeeTypesState] = useContext(NTGroupAttendeeTypesStateContext);

  const getNTGroupAttendeeTypes = () => {
    return NTGroupAttendeeTypesData.getNTGroupAttendeeTypesData(nationalTeamGroupAttendeeTypesState, setNationalTeamGroupAttendeeTypesState)
      .then((newState) => {
        if (newState ?? false) {
          newState = {
            ...newState,
            options: newState.arrayData.map((x) => { return { id: x.groupAttendeeTypeId, name: x.attendeeType } }),
            areOptionsLoaded: true
          };

          setNationalTeamGroupAttendeeTypesState({ ...newState });

          return newState;
        }

        return null;
      });
  };

  const resetNationalTeamGroupAttendeeTypesState = () => {
    setNationalTeamGroupAttendeeTypesState({
      ...NTGroupAttendeeTypesData.INITIAL_NATIONAL_TEAM_GROUP_ATTENDEE_TYPES_STATE
    });
  };

  return {
    nationalTeamGroupAttendeeTypesState,
    getNTGroupAttendeeTypes,
    resetNationalTeamGroupAttendeeTypesState
  };
};

export default useNTGroupAttendeeTypesData;