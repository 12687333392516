import { useContext, useState } from "react";

import NTGroupInfoData from "./NTGroupInfoData";
import { NTGroupInfoContext } from "./NTGroupInfoContextProvider";

const useNTGroupInfoData = () => {
  const [groupInfoState, setGroupInfoState] = useContext(NTGroupInfoContext);
  const [groupDeleteState, setGroupDeleteState] = useState(NTGroupInfoData.INITIAL_GROUP_DELETE_STATE);

  const getNTGroupInfo = (nationalTeamGroupId) => {
    return NTGroupInfoData.getNTGroupInfoData(nationalTeamGroupId, groupInfoState, setGroupInfoState);
  };

  const putNTGroupInfo = (nationalTeamGroupId, groupInfoObj) => {
    return NTGroupInfoData.putNTGroupInfoData(nationalTeamGroupId, groupInfoObj, groupInfoState, setGroupInfoState);
  };

  const deleteNTGroup = (nationalTeamGroupId) => {
    return NTGroupInfoData.deleteNTGroupData(nationalTeamGroupId, groupDeleteState, setGroupDeleteState);
  };

  return {
    groupInfoState,
    groupDeleteState,
    getNTGroupInfo,
    putNTGroupInfo,
    deleteNTGroup
  };
};

export default useNTGroupInfoData;