import { Fragment } from "react";

import NTGroupSearchGridLarge from "./NTGroupSearchGridLarge";
import NTGroupSearchGridSmall from "./NTGroupSearchGridSmall";

import global from '../../../../../common/components/GlobalStyle.module.css';

const NTGroupSearchGrid = ({ gridData, isLoading, showFilters, onToggleShowFilters, onEditGroupClicked }) => (
  <Fragment>
    {isLoading === false
      ? (
        <div className="row">
          <div className="col-xs-12 col-sm-10">
            {gridData.length > 499
              ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
              : <p>{gridData.length === 1 ? `${gridData.length} Search Result` : `${gridData.length} Search Results`}</p>
            }
          </div>
          <div className="col-xs-12 col-sm-2">
            <button className={global.ToggleButton} type="button" onClick={() => onToggleShowFilters()}>
              {showFilters === true ? 'Hide Filters' : 'Show Filters'}
            </button>
          </div>
        </div>
      ) : <div className="row usas-extra-top-margin usas-extra-bottom-margin"></div>
    }
    <NTGroupSearchGridLarge gridData={gridData} isLoading={isLoading} onEditGroupClicked={onEditGroupClicked} />
    <NTGroupSearchGridSmall gridData={gridData} isLoading={isLoading} onEditGroupClicked={onEditGroupClicked} />
  </Fragment>
);

export default NTGroupSearchGrid;