import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "../../../common/wrappers/ReactRouterDom";

import useNTGroupData from "../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupInfoData from "../../state/nationalTeamGroup/nationalTeamGroupInfo/UseNTGroupInfoData";
import useNTGroupMembersData from "../../state/nationalTeamGroup/nationalTeamGroupMembers/UseNTGroupMembersData";

import useNavRoutes from "../../../common/state/security/UseNavRoutes";
import useSecurityData from "../../../common/state/security/UseSecurityData";

const TAXONOMIES = ['NationalTeamGroupDetail', 'NationalTeamAthleteGroupDetail'];
const SCOPE = 'NationalTeamGroup';

const LOADING_PHASES = {
  NOT_LOADED: 1,
  LOADING_GROUP: 2,
  LOADING_MEMBERS: 3,
  LOADING_CONTEXT: 4,
  LOADED: 5
};

const useNTGroupsContextView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navRoutes } = useNavRoutes();
  const { onNTGroupsError } = useNTGroupData();
  const { groupInfoState, getNTGroupInfo } = useNTGroupInfoData();
  const { groupMembersState, getNTGroupMembers } = useNTGroupMembersData();
  const { contextSecurityState, getContextSecurity } = useSecurityData();
  const [loadingState, setLoadingState] = useState(LOADING_PHASES.NOT_LOADED);

  const onHomeClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.NT_GROUP_INFO?.route);
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onBackToClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.NT_GROUP_SEARCH?.route);
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  useEffect(() => {
    try {
      const nationalTeamGroupId = location?.state?.nationalTeamGroupId;

      switch (loadingState) {
        case LOADING_PHASES.NOT_LOADED:
          getNTGroupInfo(nationalTeamGroupId);
          setLoadingState(LOADING_PHASES.LOADING_GROUP);
          break;
        case LOADING_PHASES.LOADING_GROUP:
          if (groupInfoState.isObjLoaded === true) {
            getNTGroupMembers(nationalTeamGroupId);
            setLoadingState(LOADING_PHASES.LOADING_MEMBERS);
          }
          break;
        case LOADING_PHASES.LOADING_MEMBERS:
          if (groupMembersState.isObjLoaded === true) {
            const ctxStatus = getContextSecurity(groupInfoState.nationalTeamGroupId, TAXONOMIES, SCOPE);
            setLoadingState(ctxStatus === 'sent' ? LOADING_PHASES.LOADING_CONTEXT : LOADING_PHASES.LOADED);
          }
          break;
        case LOADING_PHASES.LOADING_CONTEXT:
          if (contextSecurityState.isObjLoaded === true) {
            setLoadingState(LOADING_PHASES.LOADED);
          }
          break;
        case LOADING_PHASES.LOADED:
          break;
        default:
          throw new Error('Failed to load National Team Groups context')
      }
    } catch (e) {
      onNTGroupsError(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupInfoState, groupMembersState, contextSecurityState]);

  function getTaxonomyName(groupInfoObj) {
    return groupInfoObj.hasRegistration === true ? TAXONOMIES[1] : TAXONOMIES[0];
  }

  return {
    isLoading: loadingState !== LOADING_PHASES.LOADED,
    homeRoute: navRoutes.NT_GROUP_INFO?.route,
    taxonomyName: getTaxonomyName(groupInfoState.objData),
    groupInfoObj: groupInfoState.objData || {},
    onHomeClicked,
    onBackToClicked
  };
};

export default useNTGroupsContextView;