import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTGroupData from "../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupAddData from '../../../state/nationalTeamGroup/nationalTeamGroupAdd/UseNTGroupAddData';

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";
import useGlobalRoutesData from "../../../../common/state/security/UseGlobalRoutesData";

const useNTGroupAdd = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { onNTGroupsError } = useNTGroupData();
  const { groupAddState, postNTGroup } = useNTGroupAddData();

  const onBackClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.NT_GROUP_SEARCH?.route);
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onSubmitInfoForm = (formState) => {
    try {
      const groupObj = {
        nationalTeamGroupTypeId: formState.nationalTeamGroupType[0]?.id,
        nationalTeamGroupName: formState.nationalTeamGroupName,
        nationalTeamGroupCode: formState.nationalTeamGroupCode,
        effectiveDate: formState.effectiveDate,
        expirationDate: formState.expirationDate,
        registrationStartDate: formState.hasRegistration === true ? formState.registrationStartDate : null,
        registrationEndDate: formState.hasRegistration === true ? formState.registrationEndDate : null,
        meetId: formState.hasMeet === true ? formState.meetId : null
      };

      const postNTGroupPromise = postNTGroup(groupObj);

      if (postNTGroupPromise ?? false) {
        postNTGroupPromise.then((newState) => {
          if (newState) {
            console.log(newState);
            const nationalTeamGroupId = newState.objData?.nationalTeamGroupId;

            if (nationalTeamGroupId > 0) {
              if (formState.hasRegistration === true) {
                const route = getGlobalRoute('NT_GROUP_ATHLETES');

                navigate(route?.route, { state: { nationalTeamGroupId } });
              } else {
                const route = getGlobalRoute('NT_GROUP_MEMBERS');

                navigate(route?.route, { state: { nationalTeamGroupId } });
              }
            } else {
              navigate(navRoutes.NT_GROUP_SEARCH?.route);
            }
          }
        }).catch((e) => {
          onNTGroupsError(e);
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  return {
    isSaving: groupAddState.isObjLoading,
    onSubmitInfoForm,
    onBackClicked
  };
};

export default useNTGroupAdd;