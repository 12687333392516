import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";

import useNTGroupData from "../../../state/nationalTeamGroup/UseNTGroupData";
import useNTGroupSearchData from "../../../state/nationalTeamGroupSearch/UseNTGroupSearchData";

import useNavRoutes from "../../../../common/state/security/UseNavRoutes";
import useGlobalRoutesData from "../../../../common/state/security/UseGlobalRoutesData";

const useNTGroupSearch = () => {
  const navigate = useNavigate();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { navRoutes } = useNavRoutes();
  const { onNTGroupsError } = useNTGroupData();
  const { nationalTeamGroupSearchState, nationalTeamGroupFiltersState, onToggleShowFilters, searchNTGroups
  } = useNTGroupSearchData();

  const onAddGroupClicked = (event) => {
    try {
      event?.preventDefault();

      navigate(navRoutes.NT_GROUP_ADD?.route);
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onEditGroupClicked = (event, group) => {
    try {
      event?.preventDefault();
      const nationalTeamGroupId = group?.nationalTeamGroupId;
      const route = getGlobalRoute('NT_GROUP_INFO');

      if (nationalTeamGroupId > 0 && route.route) {
        navigate(route?.route, { state: { nationalTeamGroupId } });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  const onSubmitSearchForm = (formState) => {
    try {
      const filterObject = {
        nationalTeamGroupTypeId: formState.nationalTeamGroupType[0]?.id || undefined,
        nationalTeamGroupName: formState.nationalTeamGroupName || undefined,
        nationalTeamGroupCode: formState.nationalTeamGroupCode || undefined,
        onlyCurrentNationalTeamGroups: formState.onlyCurrentNationalTeamGroups === true ? true : undefined
      };

      const searchNTGroupsPromise = searchNTGroups(filterObject);

      if (searchNTGroupsPromise ?? false) {
        searchNTGroupsPromise.catch((e) => {
          onNTGroupsError(e);
        });
      }
    } catch (e) {
      onNTGroupsError(e);
    }
  };

  return {
    showFilters: nationalTeamGroupFiltersState.showFilters === true,
    previousSearchFilters: nationalTeamGroupFiltersState.filterObject || {},
    nationalTeamGroupSearchState,
    onAddGroupClicked,
    onEditGroupClicked,
    onSubmitSearchForm,
    onToggleShowFilters
  };
};

export default useNTGroupSearch;