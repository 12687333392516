import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const getNTGroupAttendeeTypesData = (state, setState) => {
  const api = initApi(API_NAMES.PERSON, state, setState);
  const url = `/GroupAttendeeType`;
  
  return api.executeArray ? api.executeArray(url, 'GET') : null;
};

const INITIAL_NATIONAL_TEAM_GROUP_ATTENDEE_TYPES_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  areOptionsLoaded: false
}

const NTGroupAttendeeTypesData = {
  INITIAL_NATIONAL_TEAM_GROUP_ATTENDEE_TYPES_STATE,
  getNTGroupAttendeeTypesData
};

export default NTGroupAttendeeTypesData;