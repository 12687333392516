import { createContext, useState } from 'react';

import NTGroupSearchData from './NTGroupSearchData';

export const NTGroupFiltersContext = createContext();

const NTGroupFiltersContextProvider = ({ children }) => {
  const stateHook = useState(NTGroupSearchData.INITIAL_NATIONAL_TEAM_GROUP_FILTERS_STATE);

  return (
    <NTGroupFiltersContext.Provider value={stateHook}>
      {children}
    </NTGroupFiltersContext.Provider>
  );
};

export default NTGroupFiltersContextProvider;