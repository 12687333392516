import { Fragment } from 'react';

import HideIcon from '../../../../../common/components/icons/HideIcon';
import ShowIcon from '../../../../../common/components/icons/ShowIcon';
import DeleteIcon from '../../../../../common/components/icons/DeleteIcon';
import SGColumnHeader from '../../../../../common/components/grids/SGColumnHeader';

import SGConstants from '../../../../../common/utils/SGConstants';
import useSortableGrid from '../../../../../common/utils/UseSortableGrid';
import { formatDate } from '../../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../../common/utils/TimesUtils';

import global from '../../../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ event }) => (
  <tr>
    <td>{event.eventName || ''}</td>
    <td>{event.swimTime ? formatTimeForDisplay(event.swimTime) : ''}</td>
    <td>{event.swimDate ? formatDate(event.swimDate) : ''}</td>
    <td>{event.meetName || ''}</td>
  </tr>
);

const DetailTable = ({ events }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Event</th>
        <th>Swim Time</th>
        <th>Swim Date</th>
        <th>Meet Name</th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(events) && events.length > 0
        ? events.map((event, i) => <DetailTableRow event={event} key={i} />)
        : <tr><td colSpan='4'>No Events</td></tr>
      }
    </tbody>
  </table>
);

const GridRow = ({ groupAthlete, expandedId, onExpandClicked, onDeleteClicked }) => (
  <Fragment>
    <tr>
      <td colSpan='2'>{groupAthlete.preferredName || groupAthlete.firstName || ''} {groupAthlete.lastName || ''}</td>
      <td>{groupAthlete.inviteStatus || ''}</td>
      <td className='pull-right'>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onDeleteClicked(e, groupAthlete)}>
          <DeleteIcon />
        </button>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onExpandClicked(e, groupAthlete.personNationalTeamGroupId)}>
          {expandedId === groupAthlete.personNationalTeamGroupId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {expandedId === groupAthlete.personNationalTeamGroupId &&
      <tr className={global.Expanded}>
        <td colSpan="4">
          <DetailTable events={groupAthlete.personNationalTeamGroupEvent} />
        </td>
      </tr>
    }
  </Fragment>
);

const NTGroupAthletesGridLarge = ({ gridData, isLoading, expandedId, onExpandClicked, onDeleteClicked }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(gridData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'lastName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} colSpan={2} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Invite Status'} columnField={'inviteStatus'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {isLoading === true
          ? <tr><td colSpan="4">Loading...</td></tr>
          : sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((groupAthlete, i) => <GridRow key={i} groupAthlete={groupAthlete} expandedId={expandedId} onExpandClicked={onExpandClicked} onDeleteClicked={onDeleteClicked} />)
            : <tr><td colSpan="4">No Group Athletes</td></tr>
        }
      </tbody>
    </table>
  );
}

export default NTGroupAthletesGridLarge;