import { Fragment } from "react";

import useNTGroupAddForm from "./UseNTGroupAddForm";

import NTGroupTypesTreeView from "../tree/NTGroupTypesTreeView";

import Input from "../../../../../common/components/inputs/Input";
import DatePicker from "../../../../../common/components/datepickers/DatePicker";
import PrimaryButton from "../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../common/components/buttons/SecondaryButton";

import global from '../../../../../common/components/GlobalStyle.module.css';

const NTGroupAddForm = ({ submitButtonText = 'Create Group', secondaryButtonText = 'Back', onSubmitFormCallback,
  onSecondaryButtonClicked }) => {
  const {
    hasRegistration,
    hasMeet,
    formState,
    errorState,
    handleSubmit,
    updateFormState,
    onGroupTypeChanged
  } = useNTGroupAddForm(onSubmitFormCallback);

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-xs-12 col-sm-6 col-md-4">
          <NTGroupTypesTreeView
            label='Group Type*'
            name='nationalTeamGroupType'
            value={formState.nationalTeamGroupType}
            error={errorState.nationalTeamGroupType}
            message={errorState.nationalTeamGroupType}
            selectableLeavesOnly={true}
            singleSelect={true}
            onChange={(value) => { onGroupTypeChanged(value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Group Name*'
            name="nationalTeamGroupName"
            value={formState.nationalTeamGroupName}
            error={errorState.nationalTeamGroupName}
            message={errorState.nationalTeamGroupName}
            onChange={(value) => { updateFormState('nationalTeamGroupName', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <Input
            label='Group Code*'
            name="nationalTeamGroupCode"
            value={formState.nationalTeamGroupCode}
            error={errorState.nationalTeamGroupCode}
            message={errorState.nationalTeamGroupCode}
            onChange={(value) => { updateFormState('nationalTeamGroupCode', value) }} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Effective Date*"
            name="effectiveDate"
            value={formState.effectiveDate}
            error={errorState.effectiveDate}
            message={errorState.effectiveDate}
            onChange={(value) => { updateFormState('effectiveDate', value); }}
            countOfYears={30}
            startYearOffset={-25} />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <DatePicker
            label="Expiration Date*"
            name="expirationDate"
            value={formState.expirationDate}
            error={errorState.expirationDate}
            message={errorState.expirationDate}
            onChange={(value) => { updateFormState('expirationDate', value); }}
            countOfYears={30}
            startYearOffset={-25} />
        </div>
        {hasMeet &&
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input //TODO make meet lookup component
              label='Meet*'
              name="meetId"
              value={formState.meetId}
              error={errorState.meetId}
              message={errorState.meetId}
              onChange={(value) => { updateFormState('meetId', value) }} />
          </div>
        }
        {hasRegistration &&
          <Fragment>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <DatePicker
                label="Registration Start Date*"
                name="registrationStartDate"
                value={formState.registrationStartDate}
                error={errorState.registrationStartDate}
                message={errorState.registrationStartDate}
                onChange={(value) => { updateFormState('registrationStartDate', value); }}
                countOfYears={30}
                startYearOffset={-25} />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <DatePicker
                label="Registration End Date*"
                name="registrationEndDate"
                value={formState.registrationEndDate}
                error={errorState.registrationEndDate}
                message={errorState.registrationEndDate}
                onChange={(value) => { updateFormState('registrationEndDate', value); }}
                countOfYears={30}
                startYearOffset={-25} />
            </div>
          </Fragment>
        }
      </div>
      {errorState.errorOnSubmitAction &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
          </div>
        </div>
      }
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
          <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onSecondaryButtonClicked}>{secondaryButtonText}</SecondaryButton>
        </div>
      </div>
    </form>
  );
};

export default NTGroupAddForm;